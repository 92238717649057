'use client'

import { cn } from '@/lib/utils'
import { buttonVariants } from '@/components/ui/button'

export default function SkipToMainContent() {
  const skipToMain = (e) => {
    e.preventDefault()
    const firstH1 = document.querySelector('h1')
    if (firstH1) {
      firstH1.setAttribute('tabindex', '-1')
      firstH1.scrollIntoView({ behavior: 'auto' })
      firstH1.focus()
      firstH1.removeAttribute('tabindex')
    }
  }

  return (
    <a
      href="#"
      onClick={skipToMain}
      className={cn(
        'absolute text-base left-4 md:left-5 -top-2 size-1 overflow-hidden px-1 focus:size-auto bg-background',
        buttonVariants({ variant: 'secondary-link' })
      )}
    >
      Skip to main content
    </a>
  )
}
